@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%; /* Original background */
        --foreground: 0 0% 30.2%; /* #4D4D4D */
        --card: 0 0% 100%; /* Original card */
        --card-foreground: 0 0% 30.2%; /* #4D4D4D */
        --popover: 0 0% 100%; /* Original popover */
        --popover-foreground: 0 0% 30.2%; /* #4D4D4D */

        /* primary color */
        --primary: 303 65% 54%; /* #DA3BD1 */
        /* secondary color */
        --secondary: 204 91% 60%; /* #26A9FB */

        /* Additional colors */
        --warning: 36.9 100% 62.7%; /* #FFB84B */
        --success: 0.39	0.71 0.45%; /* #22c55e */
        --info: 0.60 0.91 0.60%; /* #3b82f6 */
        --light-gray: 0 0% 90%; /* #E6E6E6 */
        --dark-gray: 0 0% 30.2%; /* #4D4D4D */
        --white: 0, 0%, 100%; /* #FFFFFF */
        --dark: 0, 0%, 0%; /* #000000 */
        --disabled: 0 0% 90%; /* #E6E6E6 */
        --disabled-foreground: 0 0% 30.2%; /* #4D4D4D */

        --muted: 220 14.3% 95.9%; /* Original muted */
        --muted-foreground: 0 0% 30.2%; /* #4D4D4D */
        --accent: 220 14.3% 95.9%; /* Original accent */
        --accent-foreground: 0 0% 30.2%; /* #4D4D4D */
        --destructive: 0 84.2% 60.2%; /* Original destructive */
        --destructive-foreground: 0 0% 100%; /* #FFFFFF */
        --border: 220 13% 91%; /* Original border */
        --input: 220 13% 91%; /* Original input */
        --ring: 288 65% 65%; /* #DA3BD1 */
        --radius: 0.5rem;
        --container-padding: 1.25rem;
    }

    .dark {
        --background: 224 71.4% 4.1%; /* Original dark background */
        --foreground: 0 0% 90%; /* #E6E6E6 */
        --card: 224 71.4% 4.1%; /* Original dark card */
        --card-foreground: 0 0% 90%; /* #E6E6E6 */
        --popover: 224 71.4% 4.1%; /* Original dark popover */
        --popover-foreground: 0 0% 90%; /* #E6E6E6 */

        /* primary color in dark mode */
        --primary: 288 65% 65%; /* #DA3BD1 */
        --primary-foreground: 0 0% 90%; /* #E6E6E6 for contrast */

        /* Secondary color in dark mode */
        --secondary: 204 91% 60%; /* #26A9FB */
        --secondary-foreground: 0 0% 90%; /* #E6E6E6 for contrast */
        /* Additional colors in dark mode */
        --yellow: 36.9 100% 62.7%; /* #FFB84B */
        --light-gray: 0 0% 90%; /* #E6E6E6 */
        --dark-gray: 0 0% 30.2%; /* #4D4D4D */

        --muted: 215 27.9% 16.9%; /* Original dark muted */
        --muted-foreground: 0 0% 90%; /* #E6E6E6 */
        --accent: 215 27.9% 16.9%; /* Original dark accent */
        --accent-foreground: 0 0% 90%; /* #E6E6E6 */
        --destructive: 0 62.8% 30.6%; /* Original dark destructive */
        --destructive-foreground: 0 0% 100%; /* #FFFFFF */
        --border: 215 27.9% 16.9%; /* Original dark border */
        --input: 215 27.9% 16.9%; /* Original dark input */
        --ring: 288 65% 65%; /* #DA3BD1 */
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground antialiased;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}

/*
We hide the emulator warning at the bottom of the page.
Please remove the following class if you want to display it
 */
.firebase-emulator-warning {
    display: none;
}


.no-border:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.tabs-list {
    scrollbar-width: thin;
}

.tabs-list::-webkit-scrollbar {
    height: 4px;
}

.tabs-list::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px; 
}

.tabs-list::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
  
